import React from "react";
import { useHistory } from "react-router";
import { LIGHTBLUE } from "../constants/Colors";

import styled from "styled-components";
import { icons } from "../constants/AppData";

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: ${LIGHTBLUE};
    span {
      cursor: pointer;
      :hover {
        color: black;
      }
    }
  }
  label {
    color: grey;
  }
  @media (max-width: 800px) {
    align-items: center;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
`;
function Footer() {
  const history = useHistory();
  return (
    <Div>
      <p>
        <span> contact.yefi@gmail.com</span> |{" "}
        <span onClick={() => history.push("/contactus")}>Contact Us</span>
      </p>{" "}
      <div>
        {icons.map((item, idx) => (
          <item.icon
            key={idx}
            color={item.color}
            style={{ margin: "0px 5px", cursor: "pointer" }}
            size="1.4em"
          />
        ))}
      </div>
      <label>{`YEFI @${new Date().getFullYear()}`}</label>
    </Div>
  );
}

export default Footer;
